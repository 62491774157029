import { Injectable, inject  } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserAuthService {
  private authService = inject(AuthService);

  logoutWithRedirect(url?: string) {
    url = url || environment.auth0.authorizationParams.redirect_uri;
    this.authService.logout({ logoutParams: { returnTo: url } });
  }

  loginWithRedirect() {
    this.authService.loginWithRedirect();
  }

  getAccessTokenSilently() {
    return this.authService.getAccessTokenSilently();
  }

  get isLoading$() {
    return this.authService.isLoading$;
  }

  get isAuthenticated$() {
    return this.authService.isAuthenticated$;
  }

  get user$() {
    return this.authService.user$;
  }

  get idTokenClaims$() {
    return this.authService.idTokenClaims$;
  }
}
