import { AppInsightsLoggingService } from './core/appinsights/services/app-insights-logging.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'TripArc.LeadQueue.Frontend';
  constructor(private appInsightLoggingService:AppInsightsLoggingService){}
}
