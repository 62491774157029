import { Injectable } from '@angular/core';

@Injectable()
export class AppConfigService {
  private readonly configUrl = '/assets/config/app.config.json';
  private appConfig!: IAppConfig;

  async loadAppConfig(): Promise<IAppConfig> {
    this.appConfig = await (await fetch(this.configUrl)).json();
    return this.appConfig;
  }

  public getAppConfig(): IAppConfig {
    return this.appConfig;
  }
}

export interface IAppConfig {
  apiUrl: string;
  apiAvailabilityUrl: string;
  AppInsights: {
    instrumentationKey: string;
  };
  tmtx: {
    url: string;
  };
  azure:{
    appConfigurationConnectionString: string;
  }
}
