export const environment = {
  production: false,
  auth0: {
    domain: 'auth.uat.triparcservices.com',
    clientId: 'XhTowRHg8B3IZdXoUBklVpnauA12bP0t',
    authorizationParams: {
      audience: 'https://trip-arc.com/tap',
      redirect_uri: window.location.origin,
    },
    useRefreshTokens: false,
    cacheLocation: 'localstorage',
  },
  tmtx: {
    casePage: 'Sales/Case/',
    profilePage: 'Profile/'
  },
};
