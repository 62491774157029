import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PermissionsType } from 'src/app/shared/helpers/permissions.constant';

@Injectable({
  providedIn: 'root',
})
export class RbacService {
  private _permissions: PermissionsType[] = [];

  setPermissions(permissions: PermissionsType[]) {
    this._permissions = permissions;
  }

  getPermissions(): PermissionsType[] {
    return this._permissions;
  }

  /**
   * Method to validate if it is allowed or not according to the user's permission
   * @param permission
   */
  isAllowed(permissions: PermissionsType[]): Observable<boolean> {
    const isAllowed = permissions.some((permission) =>
      this._permissions.includes(permission)
    );
    return of(isAllowed);
  }
}
