import { NgModule } from '@angular/core';
import { RouterModule, Routes, provideRouter, withComponentInputBinding } from '@angular/router';
import { HealthComponent } from './health/health.component';
import { LayoutComponent } from "./layout/layout/layout.component";

const routes: Routes = [
  {
    path: '',
    redirectTo:'availability-calendar',
    pathMatch:'full'
  },
  {
    path: 'leads',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./features/leads/leads.module').then(m => m.LeadsModule)
      },
    ]
  },
  {
    path: 'availability-calendar',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./features/availability/availability.module').then(m => m.AvailabilityModule)
      },
    ]
  },
  {
    path: 'distribution-rules',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./features/distribution/distribution.module').then(m => m.DistributionModule)
      },
    ]
  },
  {
    path: 'error/:status',
    loadChildren: () =>
      import('./features/errors/errors.module')
        .then(m => m.ErrorsModule)
  },
  {
    path: 'health',
    component: HealthComponent
  },
  {
    path: '**',
    redirectTo: 'error/404',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableViewTransitions: true })],
  exports: [RouterModule],
  providers: [
    provideRouter(routes, withComponentInputBinding())
  ]
})
export class AppRoutingModule { }
