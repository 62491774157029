import { ErrorModel } from "../interfaces/error.model";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, throwError } from "rxjs";
import { AppInsightsLoggingService } from "src/app/core/appinsights/services/app-insights-logging.service";
import { HttpStatus } from "../enums/http-status.enums";

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  appInsightsLoggingService = inject(AppInsightsLoggingService);
  router = inject(Router);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = request.headers;

    request = request.clone({
      headers: headers
    });

    return next.handle(request)
      .pipe(
        catchError((httpError: HttpErrorResponse) => {
          if (httpError.status !== HttpStatus.NotFound) {
            // This will be handled specificaly in each component, according to each use case

            return throwError(() => httpError.message);
          } else {
            let errorMsg = '';
            if (httpError.error instanceof ErrorEvent) {
              errorMsg = `Client side Error: ${httpError.error.message}`;
              throw errorMsg;
            } else {
              console.error(`Server side error. Error Code: ${httpError.status},  Message: ${httpError.message}`);
              const errorResponse: ErrorModel = {
                type: httpError?.error?.type,
                title: httpError?.error?.title,
                status: httpError?.error?.status,
                detail: httpError?.error?.detail,
                instance: httpError?.error?.instance,
                errors: httpError?.error?.errors,
                requestDetails: httpError?.error?.requestDetails,
                profiling: httpError?.error?.profiling,
                exceptionDetails: httpError?.error?.exceptionDetails,
                traceId: httpError?.error?.traceId,
              };

              // Log the error to Dynamics Insights
              this.appInsightsLoggingService.logException(httpError);
              throw errorResponse;
            }
          }
        })
      )
  }
}
