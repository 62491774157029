import { Injectable, inject } from '@angular/core';
import { UserAuthService } from './user-auth-service';
import { take } from 'rxjs';
import { RbacService } from './rbac.service';
/**
 *  Marks the class LeadConfigService as injectable.
 */
@Injectable()
export class LeadConfigService {
  authService = inject(UserAuthService);
  rbacService = inject(RbacService);

  /**
   * Represents the state of whether the configuration is loaded or not.
   */
  loaded = false;
  
  private readonly attrPermissions = 'https://trip-arc.com/permissions';
  /**
   * @returns A Promise that resolves when the configuration is loaded.
   */
  async loadConfig(): Promise<void> {
    this.authService.user$.pipe(take(1)).subscribe(user => {
        if (user)
            this.rbacService.setPermissions(user[this.attrPermissions]);

        this.loaded = true;
        Promise.resolve();
    })
  }
}
