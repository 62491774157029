export const PERMISSIONS = {

  // Leads
  leadModifyAll: 'leadqueue:lead:modify:all',

  // Advisors Settings
  advisorModifyAll: 'leadqueue:advisor:modify:all',

  // Distribution Rules
  distributionRuleModifyAll: 'leadqueue:distributionrule:modify:all',
  distributionRuleReadAll: 'leadqueue:distributionrule:read:all',

  // Availability
  availabilityModify: 'leadqueue:availability:modify',
  availabilityModifyAll: 'leadqueue:availability:modify:all',

} as const;

export type PermissionsType = typeof PERMISSIONS[keyof typeof PERMISSIONS];
