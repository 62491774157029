// write angular interceptor

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { concatMap, filter, iif, Observable, switchMap } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  apiUrlString = ['leadqueue-api', 'availability-api'];
  appAuth = inject(AuthService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Immediately pass through if the request is not targeting '/api/'
    if (!this.canAttachToken(req)) return next.handle(req);

    const isLoaded$ = this.appAuth.isLoading$.pipe(
      filter((isLoading) => !isLoading)
    );
    

    return isLoaded$.pipe(
      concatMap(() =>
        iif(
          () => this.canAttachToken(req),
          this.appAuth.getAccessTokenSilently().pipe(
            switchMap((token) => {
              const clone = token
                ? req.clone({
                  headers: req.headers.set(
                    'Authorization',
                    `Bearer ${token}`
                  ),
                })
                : req;
              return next.handle(clone);
            })
          ),
          next.handle(req)
        )
      )
    );
  }

  canAttachToken(req: HttpRequest<any>): boolean {
    return this.apiUrlString.some(apiUrl => req.url.includes(apiUrl));
  }
}
