import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/core/auth/services/user-auth-service';
import { DestroyerHelper } from 'src/app/shared/helpers/destroyer-helper';
import { IUser } from 'src/app/shared/interfaces/IUser';
import { nexItemMenu } from '@triparc/nexus';
import { RbacService } from 'src/app/core/auth/services/rbac.service';
import { PERMISSIONS, PermissionsType } from 'src/app/shared/helpers/permissions.constant';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends DestroyerHelper implements OnInit {
  authService = inject(UserAuthService);
  isAuthenticatedAsync$ = this.authService.isAuthenticated$;
  user: IUser | null = null;
  #router = inject(Router);
  #rbacService = inject(RbacService);
  #destroyRef = inject(DestroyRef);

  nexListItemMenu = signal<nexItemMenu[]>([]);

  constructor() {
    super();
    this.subscribers.add(this.authService.user$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(user => this.user = user as IUser));
  }

  ngOnInit(): void {
    this.setMenuOptionsByPermissions(this.#rbacService.getPermissions());
  }

  setMenuOptionsByPermissions(userPermissions: PermissionsType[]) {
    const menuNames = {
      salesMenu: 'Sales',
      leadsManagementMenu: 'Leads management',
      availabilityMenu: 'Availability calendar',
      distributionRulesMenu: 'Distribution rules'
    };

    // Setting the default menu to add child menus
    this.nexListItemMenu.set([{
      name: menuNames.salesMenu,
      childMenu: [{
        name: menuNames.leadsManagementMenu,
        childMenu: []
      }]
    }]);

    for (const permission of userPermissions) {
      switch (permission) {
        case PERMISSIONS.leadModifyAll: {
          this.nexListItemMenu.update(menu => {
            const leadsManagement = menu.find(item => item.name === menuNames.salesMenu)?.childMenu?.find(item => item.name === menuNames.leadsManagementMenu);
            if (leadsManagement) {
              leadsManagement.childMenu?.push({ name: 'Leads', action: () => { this.#router.navigate(['leads/management']); } });
            }

            return menu;
          });
          break;
        }

        case PERMISSIONS.advisorModifyAll: {
          this.nexListItemMenu.update(menu => {
            const leadsManagement = menu.find(item => item.name === menuNames.salesMenu)?.childMenu?.find(item => item.name === menuNames.leadsManagementMenu);
            if (leadsManagement) {
              leadsManagement.childMenu?.push({ name: 'Lead assignment settings', action: () => { this.#router.navigate(['leads/assignment-settings']); } });
            }
            return menu;
          });
          break;
        }

        case PERMISSIONS.distributionRuleModifyAll:
        case PERMISSIONS.distributionRuleReadAll:
          // Verify if the menu already exists, if not, add it
          if (this.nexListItemMenu()
            .find(item => item.name === menuNames.salesMenu)
            ?.childMenu
            ?.find(item => item.name === menuNames.leadsManagementMenu)
            ?.childMenu
            ?.find(item => item.name === menuNames.distributionRulesMenu)) break;

          this.nexListItemMenu.update(menu => {
            const leadsManagement = menu.find(item => item.name === menuNames.salesMenu)?.childMenu?.find(item => item.name === menuNames.leadsManagementMenu);
            if (leadsManagement) {
              leadsManagement.childMenu?.push({ name: menuNames.distributionRulesMenu, action: () => { this.#router.navigate(['distribution-rules']); } });
            }
            return menu;
          });
          break;

        case PERMISSIONS.availabilityModify:
        case PERMISSIONS.availabilityModifyAll:

          // Verify if the menu already exists, if not, add it
          if (this.nexListItemMenu().find(item => item.name === menuNames.availabilityMenu)) break;

          this.nexListItemMenu.update(menu => [...menu, {
            name: menuNames.availabilityMenu,
            action: () => { this.#router.navigate(['availability-calendar']); }
          }]);
          break;

        default:
          break;
      }
    }

    // Verify if Leads management menu has child menus, if not, remove it
    if (!this.nexListItemMenu().find(item => item.name === menuNames.salesMenu)?.childMenu?.find(item => item.name === menuNames.leadsManagementMenu)?.childMenu?.length) {
      this.nexListItemMenu.update(menu => menu.filter(item => item.name !== menuNames.salesMenu));
    }

    // Sort menu children alphabetically
    this.nexListItemMenu.update(menu => {
      menu.forEach(item => {
        item?.childMenu?.forEach(subItem => {
            if (subItem.childMenu) {
              subItem.childMenu = subItem.childMenu.sort((a, b) => a.name.localeCompare(b.name));
          }
        });
      });

      return menu;
    });
  }

  login() {
    this.authService.loginWithRedirect();
  }

  logout() {
    this.authService.logoutWithRedirect();
  }
}
