import { Component } from '@angular/core';

@Component({
  selector: 'app-health',
  template: `<p>health works!</p>`,
  standalone: true
})
export class HealthComponent {

  /**
   * This component is crucial for seamless deployment in Kubernetes. Applications deployed under Docker
   * are required to have a health check page. Without it, the deployment will fail. Therefore, this
   * component is necessary as it provides that health check page.
   */
}
