import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthModule as Auth0Module } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

import { AuthInterceptor } from './auth.interceptor';
import { UserAuthService } from './services/user-auth-service';
import { LeadConfigService } from './services/lead-config.service';

export function initConfig(config: LeadConfigService, userAuthService: UserAuthService) {
  return () => userAuthService.isAuthenticated$.subscribe(() => config.loadConfig())
}
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    Auth0Module.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: environment.auth0.authorizationParams.redirect_uri
      }
    })
  ],
  exports: [ Auth0Module],
  providers: [
    UserAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    LeadConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [LeadConfigService, UserAuthService],

      multi: true,
    },
  ]
})
export class AuthModule { }
